/* Modern base styles */
:root {
  --primary-color: #2d3436;
  --secondary-color: #636e72;
  --accent-color: #0984e3;
  --background-color: #ffffff;
  --text-color: #2d3436;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

/* Modern typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1.3;
  color: var(--primary-color);
}

p {
  margin-bottom: 1.5rem;
}

code {
  font-family: 'Fira Code', source-code-pro, Menlo, Monaco, Consolas, monospace;
  background-color: #f8f9fa;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
}