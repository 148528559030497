:root {
  /* Primary Colors */
  --primary-dark: #1a2238;
  /* Deep navy blue */
  --primary-light: #243154;
  /* Lighter navy for contrast */

  /* Accent Colors */
  --accent-gold: #c0a875;
  /* Subtle metallic gold */
  --accent-silver: #e5e9f0;
  /* Soft silver */

  /* Secondary Colors */
  --secondary-light: #f0f2f5;
  /* Light background */
  --secondary-mid: #e1e5eb;
  /* Mid-tone for sections */

  /* Text Colors */
  --text-primary: #333333;
  /* Dark gray for main text */
  --text-light: #ffffff;
  /* White text */
  --text-muted: #666666;
  /* Muted text */

  /* Interactive Colors */
  --link-color: #61dafb;
  /* Bright blue for links */
  --link-hover: #90e9ff;
  /* Lighter blue for hover */
  --cta-color: #2c7be5;
  /* Call to action blue */

  /* Font Families */
  --font-primary: "Georgia", "Times New Roman", Times, serif;
  --font-secondary: "Playfair Display", Georgia, serif;

  /* Font Sizes */
  --text-xs: 0.875rem;
  /* 14px */
  --text-sm: 1rem;
  /* 16px */
  --text-base: 1.125rem;
  /* 18px */
  --text-lg: 1.25rem;
  /* 20px */
  --text-xl: 1.5rem;
  /* 24px */
  --text-2xl: 2rem;
  /* 32px */
  --text-3xl: 2.5rem;
  /* 40px */
}

.App {
  text-align: center;
  font-family: var(--font-primary);
  font-size: var(--text-base);
  line-height: 1.6;
  letter-spacing: 0.01em;
  background-color: var(--primary-dark);
  color: var(--text-light);
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--primary-light);
  border-bottom: 2px solid var(--accent-gold);
  padding: 3rem 2rem;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header h1 {
  font-size: 3.5rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  margin: 1rem 0;
  font-family: var(--font-secondary);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Base Typography */
.App,
.App-header,
.App-header h1,
h2,
p,
a {
  font-family: var(--font-primary);
  font-size: var(--text-base);
  line-height: 1.6;
  letter-spacing: 0.01em;
}

.App-header h1,
h2,
p,
strong {
  color: white;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.info-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

section {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 2rem;
  margin: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--accent-silver);
  width: 100%;
  box-sizing: border-box;
}

.contact-info,
.hours {
  flex: 1;
  min-width: 300px;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

h2 {
  font-size: var(--text-2xl);
  font-weight: 600;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid var(--accent-gold);
  padding-bottom: 0.5rem;
  color: var(--text-light);
}

p {
  margin-bottom: 1.2rem;
}

strong {
  color: var(--accent-gold);
  font-weight: 600;
  font-size: var(--text-lg);
}

a {
  text-decoration: none;
  transition: color 0.3s ease;
  color: var(--link-color);
  font-weight: 500;
}

a:hover {
  color: var(--link-hover);
}

/* Add new styles for the form */
.contact-form {
  flex-basis: 100%;
  max-width: none;
  margin: 2rem 0;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  padding: 0 1rem;
  margin: 0 1rem 1.5rem 1rem;
  border-bottom: 2px solid var(--accent-gold);
}

.form-group {
  margin-bottom: 1.5rem;
  padding: 0 1rem;
}

input,
textarea {
  width: calc(100% - 1.6rem);
  padding: 0.8rem;
  border: 1px solid var(--accent-silver);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-light);
  font-size: var(--text-sm);
  font-family: var(--font-primary);
  line-height: 1.5;
}

textarea {
  min-height: 150px;
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.submit-button {
  background-color: var(--cta-color);
  color: var(--text-light);
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 4px;
  font-size: var(--text-sm);
  font-family: var(--font-primary);
  font-weight: 600;
  letter-spacing: 0.03em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 1rem;
}

.submit-button:hover {
  background-color: var(--link-hover);
}

/* Responsive design */
@media (max-width: 768px) {
  .info-container {
    gap: 2rem;
  }

  section {
    padding: 1rem;
    margin: 0;
  }

  .contact-info,
  .hours {
    min-width: unset;
    width: 100%;
  }

  .form-group {
    padding: 0 0.5rem;
  }

  input,
  textarea {
    width: 100%;
  }

  :root {
    --text-3xl: 2rem;
    /* 32px */
    --text-2xl: 1.75rem;
    /* 28px */
    --text-xl: 1.25rem;
    /* 20px */
    --text-lg: 1.125rem;
    /* 18px */
    --text-base: 1rem;
    /* 16px */
  }

  .App-header {
    padding: 3rem 1rem;
  }

  .App-header h1 {
    font-size: 3rem;
  }
}

/* Tablet adjustments */
@media (min-width: 769px) and (max-width: 1024px) {
  .info-container {
    padding: 0 1rem;
  }
  
  .contact-info,
  .hours {
    flex-basis: calc(50% - 1rem);
  }
}

.icon {
  color: var(--accent-gold);
}

footer {
  background-color: var(--primary-light);
  border-top: 1px solid var(--accent-gold);
  font-size: var(--text-xs);
  letter-spacing: 0.02em;
}

/* Responsive adjustment for mobile */
@media (max-width: 768px) {
  .App-header h1 {
    font-size: 2.5rem;
  }
}

.status-message {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
}

.status-message.success {
  background-color: rgba(0, 255, 0, 0.1);
  color: #00ff00;
}

.status-message.error {
  background-color: rgba(255, 0, 0, 0.1);
  color: #ff0000;
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}